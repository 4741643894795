<template>
  <div class="news-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>新闻动态</span>
      <span></span>
    </header>

    <main class="page-body">
      <van-tabs v-model="active">
        <van-tab
          v-for="item in newsTypeList"
          :key="item.PK_ID"
          :title="item.sCatName"
        >
          <ul class="list">
            <li v-if="getNewsListByCateID(item.PK_ID).length > 0">
              <section
                class="list-item flex-box"
                v-for="it in getNewsListByCateID(item.PK_ID)"
                :key="it.PK_ID"
                @click="
                  $router.push({
                    name: 'NewsDetail',
                    query: { id: it.PK_ID },
                  })
                "
              >
                <div class="thumbnail">
                  <img
                    :src="
                      it.sImage1 != null && it.sImage1.startsWith('http')
                        ? it.sImage1
                        : baseUrl + it.sImage1
                    "
                  />
                </div>
                <div class="item-body">
                  <div class="title f-16">{{ it.sTitle }}</div>
                  <div class="brief">
                    <span class="pull-right"
                      ><font-awesome-icon icon="clock" />
                      {{ it.dPublishTime }}
                    </span>
                  </div>
                </div>
              </section>
            </li>
            <li v-else>
              <section class="padding no-item">
                <div class=""><img :src="emptyImg" /></div>
                <div class="nodesc">没有相关的信息</div>
              </section>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>
<script>
import { queryNews, getNewsCateList } from '@/api/website.js';
import { BASE_URL } from '@/api/index.js';
import { formatDate } from '@/utils/index.js';
function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}
export default {
  data() {
    return {
      list: [],
      newsTypeList: [],
      emptyImg: require('../assets/images/noinfo.png'),
      baseUrl: BASE_URL,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getNewsCateList().then(res => {
        this.newsTypeList = res.DataDict['data'];
      });

      queryNews(-1, 100).then(res => {
        this.list = res.DataDict['Data'];
        this.list.forEach(it => {
          it.dPublishTime = getValidTime(it.dPublishTime);
        });
      });
    },
    getNewsListByCateID(id) {
      console.log(id);
      return this.list.filter(el => el.fk_newsCatId == id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(40px);
  background: #fff;
}
.list-item {
  border-bottom: 1px solid #b8b8b8;
  padding: rem(20px) 0;
  width: 100%;
  height: 1.1rem;
}
.title {
  font-size: rem(26px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thumbnail {
  width: rem(120px);
  height: rem(120px);
  margin-right: rem(40px);
  position: relative;
  float: left;
  img {
    border-radius: rem(10px);
    width: rem(120px);
    height: rem(110px);
    border: 1px solid #b8b8b8;
  }
}

.brief {
  font-size: 12px;
  margin-top: rem(30px);
  color: #b8b8b8;
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(149px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}
</style>
